html,
body,
#__next,
main {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    color: #282828;
}

@keyframes loading-bg {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 100%;
    }
}
